import React, {useEffect, useState} from 'react';
import DataTableStyle from "../../config/DataTableStyle";
import DataTable from "react-data-table-component";
import memoize from "memoize-one";
import ManageService from "../../service/ManageService";
import Swal from "sweetalert2";
import DatasetFieldModal from "./DatasetFieldModal";
import DatasetService from "../../service/DatasetService";
import LightBtn from "../../component/buttons";
import orderArr from "../../../images/btn_positionSwich.png"
import ReactTooltip from "react-tooltip";


/**
 * 코드 최적화를 위한 Column 위치 수정 및 memoize 사용
 * setIsOpenModal, deleteField 값이 undefined 이면 수정에 필요한 열 숨김
 */
const FIELD_COLUMNS = memoize((orderDown, setSelectedRow, setIsOpenModal, deleteField, setFieldModify) => [
    {
        name: '필드 코드',
        center: true,
        selector: row => row.fdCd
    },
    {
        name: '필드 명',
        selector: row => row.fdNm,
    },
    {
        name: '데이터 분류',
        selector: row => row.fdType,
    },
    {
        name: '데이터 형식',
        selector: row => row.fdFormat,
    },
    // {
    //     name: 'Parent Field',
    //     selector: row => row.fdParent,
    // },
    {
        name: "순서변경",
        //TODO 순서변경 버튼 이벤트 넣기
        cell: row => <div>
            <button className={"orderUp"} onClick={()=>{
                Swal.fire("미구현")
            }
            }><img src={orderArr} alt={"Up"}/></button>
            <button className={"orderDown"} alt={"Down"} onClick={() => orderDown(row)}><img src={orderArr}/></button>
        </div>,
        maxWidth: '80px',
        center: true,
        omit: setIsOpenModal === undefined
    },
    {
        name: '관리',
        button: true,
        minWidth: '150px',
        cell: row =>
            <>
                {row.crUsr !== 'system' ?
                    <>
                        <LightBtn size={'tiny'} color={'green'} icon={'manage'}
                                  data-tip
                                  data-for="manage"
                                  clickHandler={() => {
                            setSelectedRow(row);
                            setIsOpenModal(true);
                            setFieldModify(true);
                            // updateField(row)
                        }}/>
                        <ReactTooltip place="top" type="dark" effect="solid" id="manage">
                            <span>필드 정보를 수정합니다.</span>
                        </ReactTooltip>
                        <LightBtn size={'tiny'} color={'red'} icon={'delete'}
                                  data-tip
                                  data-for="delete"
                                  clickHandler={() => {
                            Swal.fire({
                                title : '',
                                text : "필드를 삭제하시겠습니까?",
                                type : "warning",
                                showCancelButton : true,
                                confirmButtonClass : "btn-danger",
                                confirmButtonText : "예",
                                cancelButtonText : "아니오",
                                closeOnConfirm : false,
                                closeOnCancel : true
                            }).then(result => {
                                if(result.isConfirmed) {
                                    deleteField(row);
                                    Swal.fire("필드가 삭제되었습니다.")
                                }
                            })
                        }}/>
                        <ReactTooltip place="top" type="dark" effect="solid" id="delete">
                            <span>필드를 삭제합니다.</span>
                        </ReactTooltip>
                    </>
                    : ''}
            </>
        ,
        omit: setIsOpenModal === undefined || deleteField === undefined
    }
]);

/**
 * ! 자식 Field 확장 기능 작성
 */
const ExpandedComponent = memoize((orderDown, setSelectedRow, setIsOpenNewModal, deleteField, setFieldModify) => ({data}) => {

    return <DataTable
        columns={FIELD_COLUMNS(orderDown, setSelectedRow, setIsOpenNewModal, deleteField, setFieldModify)}
        data={data.child}
        customStyles={DataTableStyle.smCompactGrid}
        highlightOnHover
        // pointerOnHover
        noHeader
        noTableHead
        expandableRows
        expandableRowsComponent={ExpandedComponent(orderDown, setSelectedRow, setIsOpenNewModal, deleteField, setFieldModify)}
        expandableRowDisabled={row => row.child == null}
    />;
});

const FieldTableComponent = (props) => {

    const [selectedRow, setSelectedRow] = useState({});

    const [fieldList, setFieldListFn] = useState([]);

    const setFieldList = (fieldList) =>{
        setFieldListFn(fieldList);
        if(props.setFieldList){
            props.setFieldList(fieldList);
        }

    }

    const updateFieldOrder = () => {
        ManageService.updateFieldOrder(fieldList).then((r) => {
            if (r !== undefined && r.status === 200) {
            } else {
                Swal.fire("순서 수정 실패")
            }
        })
    };

    const orderDown = (e) => {
        var fdOrder = e.fdOrder;
        if (e.fdParent == null) {
            if (fdOrder < fieldList.length) {
                fieldList[fdOrder - 1] = {...fieldList[fdOrder - 1], fdOrder: fdOrder + 1};
                fieldList[fdOrder] = {...fieldList[fdOrder], fdOrder: e.fdOrder};

                var tempFieldList = fieldList[fdOrder - 1];
                fieldList[fdOrder - 1] = fieldList[fdOrder];
                fieldList[fdOrder] = tempFieldList;

                setFieldList([...fieldList]);
                updateFieldOrder(fieldList);
            }
        } else {
            for (var i in fieldList) {
                var f = fieldList[i];
                var child = f.child;
                if (fdOrder < child.length) {
                    child[fdOrder - 1] = {...child[fdOrder - 1], fdOrder: fdOrder + 1};
                    child[fdOrder] = {...child[fdOrder], fdOrder: e.fdOrder};

                    var tempChildList = child[fdOrder - 1];
                    child[fdOrder - 1] = child[fdOrder];
                    child[fdOrder] = tempChildList;

                    f = {...f, child: child};

                    setFieldList([...fieldList]);
                    updateFieldOrder(fieldList);
                }
            }
        }
    };

    const loadDatasetInfo = () => {
        DatasetService.loadDatasetInfo(props.dtsId, undefined, setFieldList,
            (data, fields) => {
            },
            (msg) => {
                Swal.fire(msg)
            })
    };

    useEffect(() => {
        if (props.fieldList === undefined && props.dtsId !== undefined) {
            loadDatasetInfo();
        } else if (props.dtsId === undefined) {
            setFieldList([]);
        } else {
            setFieldList(props.fieldList);
        }
    }, [props.fieldList, props.dtsId]);


    return (
        <>
            <DataTable
                columns={FIELD_COLUMNS(orderDown, setSelectedRow, props.setIsOpenNewModal, props.deleteField, props.setFieldModify)}
                data={fieldList}
                customStyles={props.styleType==="popup"?DataTableStyle.popupStyle:DataTableStyle.smCompactGrid}
                highlightOnHover
                // pointerOnHover
                pagination
                expandableRows
                expandableRowExpanded={row => {
                    if (row.child != null) {
                        return true;
                    } else {
                        return false;
                    }
                }}
                expandableRowsComponent={ExpandedComponent(orderDown, setSelectedRow, props.setIsOpenNewModal, props.deleteField, props.setFieldModify)}
                expandableRowDisabled={row => row.child == null}
            />
            <DatasetFieldModal dtsId={props.dtsId} isOpen={props.isOpenNewModal} setOpen={props.setIsOpenNewModal} className={props.className}
                               reloadField={loadDatasetInfo}
                               fieldList={fieldList} selectedRow={selectedRow}
                               setSelectedRow={setSelectedRow}
                               fieldModify={props.fieldModify}
            />
        </>
    );
};

export default FieldTableComponent;
