import React from 'react';
import "./popup.css"

const HtmlPopup = (props) => {

    return (
        <div>
            {window.value!==undefined?window.value.map((r) => {
                if(r!=="br") {
                    return r
                }
                else {
                    return (<br/>)
                }
            }):null}
        </div>
    );
};

export default HtmlPopup;
