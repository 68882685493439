import React, {useState} from 'react';
import {Button, Form, InputGroup, Modal} from "react-bootstrap";
import ManageService from "../../../service/ManageService";
import Swal from "sweetalert2";
import manageService from "../../../service/ManageService";
import LightBtn from "../../../component/buttons";
import {toast} from "react-toastify";

const ProjectShareModal = ({show, onHide, shareVo, setShareVo, reloadBoard }) => {

    //사용자 검색 input disable
    const [userInput, setUserInput] = useState(false);

    //사용자 검색 실패, 코드
    const [userCode, setUserCode] = useState({state: true, exp:'사용자 id 또는 이메일을 검색해주세요'})


    const hide = () => {
        onHide();
        setUserInput(false);
        setUserCode({state: true, exp:'사용자 id 또는 이메일을 검색해주세요'});
    }

    const checkUserProject = () => {
        ManageService.checkUserProject(
            {prjId: shareVo.prjId, shareUser: shareVo.shareUser}
        ).then((r)=>{
            if (r !== undefined && r.status === 200) {
                setUserInput(!r.data.state)
                if (r.data.state){
                    setUserCode({state: r.data.state, exp:r.data.exp})
                }else{
                    setUserCode({state: false, exp:''})
                }
            } else {
                setUserInput(false)
                Swal.fire("유저 검색 실패")
            }
        })
    }

    const shareProject = () => {
        const toast_id = toast.loading("공유 프로젝트 권한 설정 진행중")
        manageService.shareProject(shareVo).then((r)=>{
            if (r !== undefined && r.status === 200) {
                reloadBoard();
                hide();
                toast.update(toast_id, { render: "프로젝트 공유 성공", type: "success",autoClose: 2000, isLoading: false })
            }else{
                toast.update(toast_id, { render: "프로젝트 공유 실패", type: "error",autoClose: 2000, isLoading: false })
            }
        })
    }

    return (
        <Modal
            enforceFocus={false}
            show={show} onHide={hide}>
            <Modal.Header closeButton>
                <Modal.Title>프로젝트 공유</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <InputGroup className="mb-3">
                    <Form.Control isValid={userInput} isInvalid={userCode.state} placeholder={"공유대상"} disabled={userInput} type="text" value={shareVo.shareUser?shareVo.shareUser:""}
                                  onChange={(e) => {
                                      if (e.target.value.length == 0){
                                          setUserCode({state: true, exp:'사용자 id 또는 이메일을 검색해주세요'})
                                      }else{
                                          setUserCode({state: false, exp:''})
                                      }
                                      setShareVo({...shareVo, shareUser: e.target.value})
                                  }}/>
                    <Button disabled={userInput} variant={"outline-secondary"} onClick={checkUserProject} >확인</Button>
                    <Form.Control.Feedback>사용자 체크 완료</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">{userCode.exp}</Form.Control.Feedback>
                </InputGroup>
                <Form.Group className="mb-3">
                    <Form.Label>권한</Form.Label>
                    <Form.Select defaultValue={"G"} onChange={(e) => {
                        setShareVo({...shareVo, permissionType: e.target.value})
                    }}>
                        <option value={"R"}>Read (읽기)</option>
                        <option value={"W"}>Write (읽기, 쓰기/수정, 삭제)</option>
                        <option value={"G"}>Grant (모든 권한) </option>
                    </Form.Select>

                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <LightBtn color={"red"} size={"middle"} clickHandler={hide}>
                    취소
                </LightBtn>
                <LightBtn disabled={!userInput} color={"green"} size={"middle"} clickHandler={shareProject}>
                    공유
                </LightBtn>
            </Modal.Footer>
        </Modal>
    );
};

export default ProjectShareModal;
