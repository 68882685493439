import {Dropzone, FileItem} from "@dropzone-ui/react";
import React, {useState} from 'react';
import {toast} from "react-toastify";

export default function DropZoneExcelUpload(props) {
    const [files, setFiles] = useState([]);

    // const [uploadLog, setUploadLog] = useState([]);
    const updateFiles = (incommingFiles) => {
        setFiles(incommingFiles);
    };
    const onDelete = (id) => {
        setFiles(files.filter((x) => x.id !== id));
    };
    return (
        <div className={"dropzone-black"}>
            <Dropzone
                onChange={updateFiles}
                value={files}
                onClean
                accept={".xlsx, .csv"}
                maxFiles={1}
                maxFileSize={104857600}
                label={"파일을 Drag & Drop 하거나 클릭 후 선택하면 자동으로 업로드 됩니다."}
                minHeight={"195px"}
                maxHeight={"500px"}
                localization={"EN-en"}
                url={"/dataset/excel/upload/" + props.dtsId + "/" + props.prjId + "/" + props.uploadType}
                method={"POST"}
                config={{
                    headers: {
                        "content-type": "multipart/form-data"
                    },
                }}
                uploadOnDrop={true}
                onUploadFinish={(r) => {
                    console.log(r);
                    var errorDataList = r[0].serverResponse;
                    if(errorDataList.length !== undefined) {
                        props.setTabKey("error");
                        props.setExcelDataError(errorDataList);
                    }
                    else {
                        var message = errorDataList.message;
                        var excelValidation = errorDataList.excelUploadVO.dataValidation;
                        if(message === undefined) {
                            message = "업로드 실패";
                            toast(message, {type: "error", autoClose: 3000});
                        }
                        else {
                            const successMessage = message.split("||")[0];
                            const dataCount = message.split("||")[1];
                            const dataSize = message.split("||")[2];
                            toast(successMessage, {type: "success", autoClose: 3000});

                            props.setExcelDataCount(dataCount);
                            props.setExcelDataSize(dataSize);

                            props.setExcelDataResult(true);
                            props.setExcelValidation(excelValidation);
                        }
                        props.setIsOpenAllDataModal(false);
                        props.setExcelDataError([]);
                    }
                    // setUploadLog(r);
                    props.loadFiles();
                }}
            >
                {files.map((file) => (
                    <FileItem
                        {...file}
                        key={file.id}
                        onDelete={onDelete}
                        alwaysActive
                        localization={"EN-en"}
                        preview
                        info
                        resultOnTooltip
                    />
                ))}
            </Dropzone>
        </div>
    );
}
