import React, {useEffect, useState} from 'react';
import memoize from "memoize-one";
import LightBtn from "../../component/buttons";
import {Row} from "react-bootstrap";
import DataTable from "react-data-table-component";
import DataTableStyle from "../../config/DataTableStyle";
import Swal from "sweetalert2";
import ReactTooltip from "react-tooltip";

const PROJECT_USER_COLUMNS = memoize((id, prmsnTp, setRepermission, setRePermissionVo, openRevokeCheckAlert, setRevoke, newRePermissionOpen) => [
    {
        name: '사용자',
        selector: row => row.tgtUsr,
    },
    {
        name: '권한',
        // selector: row => row.prmsnTp,
        cell: row =>{
            if(row.prmsnTp ==="G")
                return "G (모든 권한)";
            if(row.prmsnTp ==="R")
                return "R (읽기)";
            if(row.prmsnTp ==="W")
                return "W (쓰기)";
            return row.prmsnTp;
        }
    },
    {
        name: '관리',
        button: true,
        minWidth: '150px',
        cell: row => <>
            {(prmsnTp !== 'G') || (row.tgtType === 'OWNED') ? "" :
                <>
                    <LightBtn size={'tiny'} color={'green'} icon={'manage'}
                              data-tip
                              data-for="pjmanage"
                              clickHandler={() => {
                                  setRepermission(id, row.tgtUsr, row.prmsnTp, setRePermissionVo)
                                  newRePermissionOpen()
                              }}>
                    </LightBtn>
                    <ReactTooltip place="top" type="dark" effect="solid" id="pjmanage">
                        <span>공유자의 프로젝트 권한을 재설정합니다.</span>
                    </ReactTooltip>
                    <LightBtn size={'tiny'} color={'red'} icon={'delete'}
                              data-tip
                              data-for="pjdelete"
                              clickHandler={() => {
                                  openRevokeCheckAlert(id, row.tgtUsr, setRevoke)
                              }}>
                    </LightBtn>
                    <ReactTooltip place="top" type="dark" effect="solid" id="pjdelete">
                        <span>공유자의 프로젝트 권한을 해제합니다</span>
                    </ReactTooltip>
                </>

            }

        </>,
    }
]);

const DATASET_USER_COLUMNS = memoize((id, prmsnTp, setRepermission, setRePermissionVo, openRevokeCheckAlert, setRevoke, newRePermissionOpen) => [
    {
        name: '사용자',
        selector: row => row.tgtUser,
    },
    {
        name: '권한',
        // selector: row => row.prmsnTp,
        cell: row => {
            if(row.prmsnTp==="G")
                return "G (모든 권한)";
            if(row.prmsnTp==="R")
                return "R (읽기)";
            if(row.prmsnTp==="W")
                return "W (쓰기)";
            return row.prmsnTp
        }
    },
    {
        name: '관리',
        button: true,
        minWidth: '150px',
        cell: row => <>
            {(prmsnTp !== 'G') || (row.tgtType === 'OWNED') ? "" :
                <>
                    <LightBtn size={'tiny'} color={'green'} icon={'manage'}
                              data-tip
                              data-for="dataSetmanage"
                              clickHandler={() => {
                                  setRepermission(id, row.tgtUser, row.prmsnTp, setRePermissionVo)
                                  newRePermissionOpen()
                              }}>
                    </LightBtn>
                    <ReactTooltip place="top" type="dark" effect="solid" id="dataSetmanage">
                        <span>데이터셋 공유자 권한을 재설정합니다.</span>
                    </ReactTooltip>
                    <LightBtn size={'tiny'} color={'red'} icon={'delete'}
                              data-tip data-for="dataSetdelete"
                              clickHandler={() => {
                                  openRevokeCheckAlert(id, row.tgtUser, setRevoke)
                              }}>
                    </LightBtn>
                    <ReactTooltip place="top" type="dark" effect="solid" id="dataSetdelete">
                        <span>데이터셋 공유 권한을 해제합니다.</span>
                    </ReactTooltip>
                </>}

        </>,
    }

]);

//dataType = true 프로젝트. false 데이터셋
const DataShareUserComponent = ({
                                    id,
                                    modal,
                                    setRepermission,
                                    setRevoke,
                                    loadShareList,
                                    revokeUserPermission,
                                    infoVo,
                                    dataType
                                }) => {

    const [shareList, setShareList] = useState([])

    /***
     * 권한 재설정 모달
     * ***/
    const [isOpenRePermission, setIsOpenRePermission] = useState(false);

    const newRePermissionClose = () => {
        setIsOpenRePermission(false);
    }
    const newRePermissionOpen = () => {
        setIsOpenRePermission(true);
    }

    const loadShareUserList = () => {
        loadShareList(id).then(r => {
            if (r !== undefined && r.status === 200) {
                setShareList(r.data);
            }
        })
    }

    const [rePermissionVo, setRePermissionVo] = useState({shareUser: '', permissionType: 'G'})

    const revoke = (revokeVo) => {
        return revokeUserPermission(revokeVo)
    }

    const openRevokeCheckAlert = (id, shareUser, setRevoke) => {
        Swal.fire({
            title: '공유 권한 해제',
            text: "공유를 해제 하시겠습니까?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '공유 해제',
            cancelButtonText: '취소'
        }).then((result) => {
            if (result.isConfirmed) {
                const revokeVo = setRevoke(id, shareUser)
                revoke(revokeVo).then((result) => {
                    loadShareUserList();
                });
            }
        })
    }


    useEffect(() => {
        loadShareUserList();
    }, [])

    const modalComponent = React.cloneElement(
        modal, {
            isOpen: isOpenRePermission,
            onHide: newRePermissionClose, rePermissionVo: rePermissionVo,
            setRePermissionVo: setRePermissionVo, reload: loadShareUserList
        }
    )


    return (
        <>
            <Row>
                <DataTable
                    columns={
                        dataType ? PROJECT_USER_COLUMNS(id, infoVo.prmsnTp, setRepermission, setRePermissionVo, openRevokeCheckAlert, setRevoke, newRePermissionOpen) : DATASET_USER_COLUMNS(id, infoVo.prmsnTp, setRepermission, setRePermissionVo, openRevokeCheckAlert, setRevoke, newRePermissionOpen)}
                    data={shareList}
                    customStyles={DataTableStyle.customStyles}
                    highlightOnHover
                    // pointerOnHover
                    pagination
                />
            </Row>
            {modalComponent}
        </>
    );
};

export default DataShareUserComponent;
