import React, {useEffect, useState} from 'react';
import {Col, Form, Modal, Row, Tab, Tabs} from "react-bootstrap";
import TaskPropertyComponent from "./TaskPropertyComponent";
import LightBtn from "../../../component/buttons";
import GraphService from "../../../service/GraphService";
import TaskService from "../../../service/TaskService";
import "./TaskEditorModal.css"
import {toast} from "react-toastify";
import TaskOutputDataTable from "./TaskOutputDataTable";
import {format} from 'date-fns'
import TaskExceptionList from "./TaskExceptionList";
import TaskOutputParamTable from "./TaskOutputParamTable";
import TaskInputParamTable from "./TaskInputParamTable";
import Swal from "sweetalert2";


// const precdTaskParamListColumns = [
//     {
//         name: 'Input Param Name',
//         selector: row => row
//     },
// ]

/**
 * 노드 정보 수정 모달
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const TaskEditorModal = ({taskId, show, handle, prcId, nodes, addEdge, refreshGraph}) => {

    /**
     *= 수정 사항을 임시 저장할 State 향후 저장 과정을 거져 실제 값으로 반영
     */
    const [copiedTask, setCopiedTask] = useState(undefined);

    const [nodeDef, setNodeDef] = useState({});


    const [tabKey, setTabKey] = useState("info");

    const [precdOutputParams, setPrecdOutputParams] = useState([]);
    // const [precdOutputData, setPrecdOutputData] = useState([]);


    useEffect(() => {

        if (taskId) {
            const taskMeta = TaskService.getTask(taskId);

            if (taskMeta !== undefined && taskMeta !== null) {
                //= 선택된 노드의 카피본은 저장함
                setCopiedTask(taskMeta)

                //= 노드 정의 가져오기
                if (taskMeta.taskType) {
                    const _nodeDef = GraphService.getNodeDefByNodeType(taskMeta.taskType);
                    setNodeDef(_nodeDef);
                }
            }

            setTabKey("info")
        }


    }, [taskId])

    /**
     *= 노드 정보 및 실행 결과를 저장함
     */
    const saveNode = () => {
        TaskService.taskSaveToLocal(copiedTask.taskId, copiedTask);
        refreshGraph();
    }

    const removeNode = () => {
        TaskService.removeTask(copiedTask.taskId);
        refreshGraph();
    }


    /**
     * = 이전 노드 가져오기
     */
    const precdTask = copiedTask ? TaskService.getTask(copiedTask.precdTaskId) : null;

    useEffect(() => {
        if (copiedTask && copiedTask.precdTaskId) {
            if (precdTask && precdTask.lastTest) {
                if (precdTask.taskState === "DONE") {

                    const precdOutput = precdTask.lastTest.node.outputParams
                    //초기값으로 전체 Input값으로 처리
                    if (!copiedTask.taskDef || !copiedTask.taskDef.inputParams) {
                        setSelectedInputParam(precdOutput);
                    }

                    //선택을 만들기위한 기본 객체 생성
                    setPrecdOutputParams(precdOutput);
                    // const precdTaskOutputData = precdTask.lastTest.outputData;
                    // setPrecdOutputData(precdTaskOutputData)
                }
            }
        }
    }, [copiedTask ? copiedTask.precdTaskId : null]);


    /**
     *= 노드 테스트 수행
     */
    const testNode = () => {
        if (copiedTask) {

            let inputData = [];
            //TODO input data 삽입 / 필터링
            if (precdTask && precdTask.lastTest && precdTask.lastTest.outputData)
                inputData = precdTask.lastTest.outputData;


            const id = toast.loading("테스트 수행중 ...")
            TaskService.runTask(copiedTask,inputData,
                (data) => {
                    setCopiedTask({
                        ...copiedTask,
                        lastTest: data,
                        taskState: data.state
                    })
                    toast.update(id, {render: "테스트 성공.", type: "success", autoClose: 2000, isLoading: false});
                    refreshGraph();
                }, (status, msg, data) => {
                    if (status === 200 && data) {
                        setCopiedTask({
                            ...copiedTask,
                            lastTest: data,
                            taskState: "ERROR"
                        })
                    }
                    setTabKey("error");
                    toast.update(id, {render: `테스트 실패 : ${msg}`, type: "error", autoClose: 2000, isLoading: false});
                    refreshGraph();
                })
        }

    }


    const getInputParam = () => {
        if (copiedTask && copiedTask.taskDef && copiedTask.taskDef.inputParams) {
            return copiedTask.taskDef.inputParams
        } else {
            return [];
        }
    }
    const setSelectedInputParam = (selectedParams) => {
        if (copiedTask && copiedTask.taskDef) {
            const task = {
                ...copiedTask,
                taskDef: {
                    ...copiedTask.taskDef,
                    inputParams: selectedParams
                }
            }
            setCopiedTask(task)
        }
    }

    //= 입력 파라미터 변경 콜백
    // const handleInputRowSelected = React.useCallback(({allSelected, selectedCount, selectedRows}) => {
    //     console.log('selectedRows',selectedRows)
    //     // setSelectedInputParam(selectedRows)
    // }, [copiedTask]);


    let testTimeMessage = <p>[수행이력 없음]</p>

    try{
        if(copiedTask && copiedTask.lastTest){

            const startTime = format(copiedTask.lastTest.prcsStartTime, 'yyyy-MM-dd hh:mm:ss');
            const prcsTime = (copiedTask.lastTest.prcsEndTime - copiedTask.lastTest.prcsStartTime) + "ms";

            testTimeMessage = <p>
                테스트 수행 일시 : {startTime}, 수행 소요 시간 : {prcsTime}
            </p>
        }
    }catch(e){

    }



    return (
        <Modal
            enforceFocus={false}
            dialogClassName="modal-90w"
            show={show}
            onHide={() => {
                setTabKey("info");
                handle(false);
            }}>
            <Modal.Header closeButton>
                <Modal.Title>작업
                    정보 {copiedTask ? ` - ${copiedTask.taskState}` : ""}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <h5>{nodeDef ? nodeDef.nodeTitle + " ( " + nodeDef.nodeName + " )" : ""}</h5>
                        <p className={"mb-1"}>{nodeDef ? nodeDef.nodeDescription : ""}</p>
                    </Col>
                </Row>
                <Tabs
                    activeKey={tabKey}
                    onSelect={(e) => {
                        setTabKey(e);
                    }}
                    defaultActiveKey="info"
                    transition={false}
                    className="mb-3 task-info-tab"
                >
                    <Tab eventKey="info" title="정보">
                        <Row>
                            <Col md={5}>
                                <h6>작업 정보</h6>
                                <Form.Group className="mb-3">
                                    <Form.Label>작업 명</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        type="text"
                                        placeholder="Task Name"
                                        value={copiedTask ? copiedTask.taskNm : ""}
                                        onChange={(e) => {
                                            setCopiedTask({...copiedTask, taskNm: e.target.value})
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>선행 작업</Form.Label>
                                    {/*TODO BUG FIX, 다른 노드 선택 후 입력이 없는 노드 선택시 해당 Select에서 발생하는 버그 수정 필요*/}
                                    <Form.Select size="sm" value={copiedTask ? copiedTask.precdTaskId : ''}
                                                 onChange={(e) => {
                                                     const v = Number(e.target.value);
                                                     setCopiedTask({
                                                         ...copiedTask,
                                                         precdTaskId: v
                                                     })
                                                 }}>
                                        <option value={''} defaultValue>미선택</option>
                                        <option value="0">Start</option>
                                        {
                                            nodes.map(n => {
                                                if (n.id === '0') {
                                                    return '';
                                                }
                                                const t = TaskService.getTask(n.id);
                                                if (!t) {
                                                    console.log(n.id);
                                                }
                                                if (t !== undefined && t.taskId) {
                                                    if ((!t && !copiedTask) && copiedTask.taskId === t.taskId) {
                                                        return '';
                                                    } else return (
                                                        <option key={`${t.taskId}:precdTaskId`} value={t.taskId}>
                                                            {`${t.taskId} : ${t.taskNm}`}</option>
                                                    );
                                                }
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>입력 파라미터</Form.Label>


                                    {
                                        precdTask ?
                                            <TaskInputParamTable
                                                selected={getInputParam()}
                                                setSelected={setSelectedInputParam}
                                                params={precdOutputParams}
                                            />
                                            : copiedTask && copiedTask.precdTaskId === 0 ? <p>시작 노드는 입력을 가지지않습니다.</p> :
                                                <p>선행 노드를 먼저 실행해야합니다.</p>
                                    }

                                </Form.Group>
                            </Col>
                            <Col md={7}>
                                <Form.Group className="mb-3">
                                    <h6>작업 설정</h6>
                                    <TaskPropertyComponent prcId={prcId} nodeDef={nodeDef}
                                                           precdTask={precdTask}
                                                           node={copiedTask} setNode={setCopiedTask}/>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>출력 파라미터</Form.Label>
                                    {
                                        copiedTask ? copiedTask.lastTest ?
                                            (<TaskOutputParamTable
                                                lastTest={copiedTask ? copiedTask.lastTest : undefined}/>)
                                            : (<p>먼저 테스트를 수행해주세요.</p>) : null
                                    }
                                </Form.Group>
                            </Col>
                        </Row>
                    </Tab>
                    <Tab eventKey="test" title="테스트">
                        <Row>
                            <Col>
                                <h6>마지막 테스트 결과</h6>
                                {testTimeMessage}
                            </Col>
                        </Row>
                        <Row>
                            <Form.Label>출력 데이터</Form.Label>
                            <TaskOutputDataTable lastTest={copiedTask ? copiedTask.lastTest : undefined}/>
                        </Row>
                    </Tab>
                    <Tab eventKey="error" title="에러"
                         disabled={!(copiedTask && copiedTask.lastTest
                             && copiedTask.lastTest.exceptions)}>
                        <TaskExceptionList exceptions={copiedTask && copiedTask.lastTest
                        && copiedTask.lastTest.exceptions ? copiedTask.lastTest.exceptions : undefined}/>
                    </Tab>
                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                <LightBtn color={"red"} size={"middle"} clickHandler={() => {


                    Swal.fire({
                        title: '정말로 삭제하시겠습니까?',
                        text: `삭제된 태스크는 저장하면 완전히 사라집니다.`,
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        cancelButtonText: '취소',
                        confirmButtonText: '삭제'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            removeNode();
                            handle(false);
                        }
                    })


                }}>
                    삭제
                </LightBtn>
                <LightBtn color={"green"} size={"middle"} clickHandler={() => {

                    saveNode();
                    handle(false);
                }}>

                    적용
                </LightBtn>
                <LightBtn color={"blue"} size={"middle"} clickHandler={() => {
                    testNode();
                    setTabKey("test")

                }}>
                    테스트
                </LightBtn>
            </Modal.Footer>
        </Modal>
    );
};

export default TaskEditorModal;
