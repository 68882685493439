import React, {useEffect, useRef, useState} from 'react';
import {useParams} from "react-router-dom";
import {Button, Card, Container, Offcanvas, Stack} from "react-bootstrap";
import "./ProcessEditor.css"
import ProcessGraphNodeDndSidebar from "./component/graph/ProcessGraphNodeDndSidebar";
import ProcessGraph from "./component/graph/ProcessGraph";
import {Title2} from "../component/titles";
import LightBtn from "../component/buttons";
import AddDatasetModal from "./component/AddDatasetModal";
import DatasetListComponent from "./component/DatasetListComponent";
import ProcessService from "../service/ProcessService";
import FieldTableComponent from "../manage/component/FieldTableComponent";
import DataViewerModal from "../manage/DataViewerModal";
import TaskService from "../service/TaskService";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import GraphService from "../service/GraphService";
import BoxBlack from "../component/Boxes";
import ReactTooltip from "react-tooltip";
import withReactContent from "sweetalert2-react-content";
import ProjectType from "../../popup/component/ProjectType";

const ProcessEditor = (props) => {

    const params = useParams();
    const ReactSwal = withReactContent(Swal)

    //= 프로세스 정보

    //= OffCanbus 상태
    const [openTaskList, setOpenTaskList] = useState(false);
    const [openDatasetList, setOpenDatasetList] = useState(false);

    //= 그래프 Ref
    const graphRef = useRef();
    //= 저장중 관리
    const [saving, setSaving] = useState(false);


    //= 데이터셋 목록 관리
    const [selectedDataset, setSelectedDataset] = useState(undefined);

    const [datasetList, setDatasetList] = useState([]);
    const [addDatasetModalOpen, setAddDatasetModalOpen] = useState(false);
    const [dataViewerModalOpen, setDataViewerModalOpen] = useState(false);

    //태스크 유형 정의
    const [nodeMap, setNodeMap] = useState({});


    const prcId = params.prcId ? params.prcId : props.prcId;

    const initNodeDef = () => {
        ProcessService.getPlugins().then((r) => {
            if (r && r.status === 200) {
                let map = {}
                r.data.forEach((d) => {

                    if (map[d.nodeType]) {
                        map[d.nodeType].push(d);
                    } else {
                        map[d.nodeType] = [d];
                    }
                })
                const sortMap = {};
                Object.keys(map).sort().forEach(function (key) {
                    sortMap[key] = map[key];
                });
                setNodeMap(sortMap);
                GraphService.initNodeDef(r.data);
            }
        })
    }


    const reloadProcessDataset = () => {
        ProcessService.getProcessDatasetList(prcId).then(r => {
            if (r !== undefined && r.status === 200) {
                setDatasetList(r.data);
            } else {
                //TODO 예외처리
            }
        })
    }


    const saveProcess = () => {
        setSaving(true)

        const id = toast.loading("프로세스를 저장하고 있습니다.")
        let tasks = TaskService.getAllTasks();
        TaskService.saveProcess(prcId, tasks).then(r => {
            if (r && r.status === 200) {
                console.log('TaskService.saveProcess', r.data);
                TaskService.initTaskMapByList(r.data);
                toast.update(id, {render: "저장되었습니다.", type: "success", autoClose: 2000, isLoading: false});

                graphRef.current.refreshGraph();

            }
        }).catch((reason) => {
            console.log('reason', reason)
            toast.update(id, {render: "저장에 실패했습니다.", type: "error", autoClose: 2000, isLoading: false});
        }).finally(() => {
            setSaving(false);
        })
    }

    const refreshGraph = () => {
        if (graphRef.current) {
            graphRef.current.refreshGraph();
        }
    }
    const testFailureHandler = (status, msg, data, sendTask, toastId) => {
        toast.update(toastId, {
            render: `수행 명령 실패 - [${sendTask.taskId}] ${sendTask.taskNm} : ${msg}`,
            type: "error",
            autoClose: 2000,
            isLoading: false
        });
        refreshGraph();
    }
    const testSuccessHandler = (data, sendTask, toastId) => {
        toast.update(toastId, {
            render: `수행 명령 성공 - [${sendTask.taskId}] ${sendTask.taskNm}`,
            type: "success",
            autoClose: 2000,
            isLoading: false
        });
        refreshGraph();
        const next = TaskService.getNextFromTreeMap(sendTask.taskId);
        if (next !== null) {
            // console.log('next', next)
            //종료
            next.forEach(t => {
                const id = toast.loading(`[${t.taskId}] ${t.taskNm}  : 작업 수행 중... `)
                TaskService.runTask(t, data.outputData, testSuccessHandler, testFailureHandler, id)
            });
        } else {
            // console.log('no next', sendTask)
        }

    }

    const testProcess = () => {
        console.log('Test Process Start')
        TaskService.initAllState();
        let start = TaskService.getNextFromTreeMap(0);

        if (start === null) {
            Swal.fire('시작노드에 연결된 태스크(노드)가 없습니다.');
        } else {

            start.forEach(task => {
                const id = toast.loading(`[${task.taskId}] ${task.taskNm} : 작업 수행 중... `)
                TaskService.runTask(task, [], testSuccessHandler, testFailureHandler, id)
            })

        }
    }

    useEffect(() => {


        initNodeDef();
        reloadProcessDataset();


        ReactSwal.fire({
            title: '데이터 공정을 만들어볼까요?',
            html: (
                <div className={"project-type-container"}>
                    <div className={"project-type-btn"} onClick={()=>{
                        setOpenDatasetList(true)
                        Swal.close()
                    }}>
                        <div className={"typeSelect-ttl"}>
                            <h3 className={"project-type-title"}>{"데이터셋 추가"}</h3>
                            <span className={"typeSelect-ettl"}>{"Add Dataset"}</span>
                        </div>
                        {/*<div className={"typeSelect-img " + props.icon}></div>*/}
                        <div className={"typeSelect-sttl"}>{"작업에서 활용할 데이터셋을 추가합니다."}</div>

                    </div>
                    <div className={"project-type-split"}></div>
                    <div className={"project-type-btn"} onClick={()=>{
                        setOpenTaskList(true);
                        Swal.close()
                    }}>
                        <div className={"typeSelect-ttl"}>
                            <h3 className={"project-type-title"}>{"작업 추가"}</h3>
                            <span className={"typeSelect-ettl"}>{"Add Task"}</span>
                        </div>
                        {/*<div className={"typeSelect-img " + props.icon}></div>*/}
                        <div className={"typeSelect-sttl"}>{"데이터를 가공할 작업을 추가합니다."}</div>

                    </div>
                </div>
            ),
            confirmButtonText: '시작하기',
            backdrop:false
        })
    }, []);

    return (
        <Container fluid className={"process-editor"}>
            <Card>
                <Card.Header className={"pb-2 border-bottom"}>
                    <Title2>데이터 공정 프로세스 관리</Title2>
                    <Stack direction={"horizontal"}>
                        <Button variant={"blue"}
                                data-tip data-for="proEditAdd"
                                onClick={() => {
                                    setOpenTaskList(!openTaskList);
                                }}>작업 추가하기</Button>
                        <ReactTooltip place="top" type="dark" effect="solid" id="proEditAdd">
                            <span>데이터 공정 작업을 추가합니다</span>
                        </ReactTooltip>

                        <LightBtn className={"ms-auto me-2"} color={"blue"}
                                  data-tip data-for="proEditTest"
                                  clickHandler={() => {
                                      testProcess();
                                  }}>테스트</LightBtn>
                        <ReactTooltip place="top" type="dark" effect="solid" id="proEditTest">
                            <span>데이터 공정 프로세스를 테스트합니다.</span>
                        </ReactTooltip>

                        <LightBtn color={"green"}
                                  data-tip data-for="proEditWork"
                                  clickHandler={() => {
                                      testProcess();
                                  }}>실행</LightBtn>
                        <ReactTooltip place="top" type="dark" effect="solid" id="proEditWork">
                            <span>데이터 공정 프로세스를 실행합니다.</span>
                        </ReactTooltip>
                        <LightBtn className={"ms-2"} color={"green"}
                                  data-tip data-for="proEditSave"
                                  clickHandler={
                                      () => {
                                          if (saving) {
                                              Swal.fire('이미 수행중인 작업입니다.');
                                          } else {
                                              saveProcess();
                                          }
                                      }
                                  }>저장</LightBtn>
                        <ReactTooltip place="top" type="dark" effect="solid" id="proEditSave">
                            <span>데이터 공정 프로세스를 저장합니다.</span>
                        </ReactTooltip>

                        <Button className={"ms-auto"} variant={"blue"}
                                data-tip data-for="proEditDataSet"
                                onClick={() => {
                                    setOpenDatasetList(!openDatasetList)
                                }}>데이터셋 관리</Button>
                        <ReactTooltip place="top" type="dark" effect="solid" id="proEditDataSet">
                            <span>필요한 데이터셋을 가져옵니다.</span>
                        </ReactTooltip>

                    </Stack>
                </Card.Header>
                <Card.Body className={"process_graph"}>
                    <BoxBlack>
                        <ProcessGraph prcId={prcId}
                                      width={"100%"}
                                      height={"70vh"}
                                      ref={graphRef}
                        />
                    </BoxBlack>

                </Card.Body>
            </Card>


            <Offcanvas
                className={"process-offcanvas task-list-offcanvas"}
                show={openTaskList}
                onHide={setOpenTaskList}
                backdrop={false}
                {...props}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>작업 유형</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className={"dndflow"}>
                    <ProcessGraphNodeDndSidebar
                        nodeMap={nodeMap}
                        graphRef={graphRef}
                    />
                </Offcanvas.Body>
            </Offcanvas>

            <Offcanvas
                className={"process-offcanvas dataset-list-offcanvas"}
                placement={'end'}
                show={openDatasetList}
                onHide={setOpenDatasetList}
                backdrop={false}
                {...props}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>데이터셋</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className={"dndflow"}>
                    <Card>
                        <Card.Header>
                            <Stack direction={"horizontal"}>
                                <Title2>데이터셋 목록</Title2>
                                <LightBtn
                                    color="green"
                                    size="small"
                                    data-tip data-for="proEditDataSetAdd"
                                    clickHandler={() => {
                                        setAddDatasetModalOpen(true)
                                    }}>+ 데이터셋 추가</LightBtn>
                                <ReactTooltip place="top" type="dark" effect="solid" id="proEditDataSetAdd">
                                    <span>필요한 데이터셋을 추가합니다.</span>
                                </ReactTooltip>
                                <AddDatasetModal
                                    isOpen={addDatasetModalOpen}
                                    setOpen={setAddDatasetModalOpen}
                                    reloadProcessDataset={reloadProcessDataset}
                                />
                            </Stack>
                        </Card.Header>
                        <Card.Body>
                            <DatasetListComponent
                                prcId={params.prcId}
                                datasetList={datasetList}
                                setDatasetList={setDatasetList}
                                rowClick={row => {
                                    setSelectedDataset(row);
                                }}
                                reloadProcessDataset={reloadProcessDataset}
                            />
                        </Card.Body>
                        <span style={{"fontSize": "13px"}}> * 데이터셋 행을 클릭하면 필드 목록을 볼 수 있습니다.</span>
                    </Card>
                    {
                        selectedDataset ?
                            <Card className="mt-3">
                                <Card.Header>
                                    <Stack direction={"horizontal"}>
                                        <Title2>필드 목록</Title2>
                                        <LightBtn
                                            color="green"
                                            size="small"
                                            data-tip data-for="proEditDataSetFieldData"
                                            clickHandler={() => {
                                                setDataViewerModalOpen(true)
                                            }}>데이터 보기</LightBtn>
                                        <ReactTooltip place="left" type="dark" effect="solid"
                                                      id="proEditDataSetFieldData">
                                            <span>필드에 저장된 데이터들을 볼 수 있습니다.</span>
                                        </ReactTooltip>
                                    </Stack>
                                    <DataViewerModal open={dataViewerModalOpen} openHandler={setDataViewerModalOpen}
                                                     data-tip data-for="proEditDataSetFieldDataViewer"
                                                     dtsId={selectedDataset ? selectedDataset.dtsId : undefined}/>
                                    <ReactTooltip place="left" type="dark" effect="solid" id="proEditDataSetFieldData">
                                        <span>필드에 저장된 데이터들을 볼 수 있습니다.</span>
                                    </ReactTooltip>
                                </Card.Header>
                                <Card.Body>
                                    <FieldTableComponent
                                        dtsId={selectedDataset ? selectedDataset.dtsId : undefined}
                                        fieldList={undefined}
                                        setFieldList={undefined}
                                        isOpenNewModal={undefined}
                                        setIsOpenNewModal={undefined}
                                        deleteField={undefined}
                                    />

                                </Card.Body>
                            </Card>
                            : null
                    }

                </Offcanvas.Body>
            </Offcanvas>
        </Container>
    );
};

export default ProcessEditor;
