import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from 'react';
import "./ProcessGraph.css"
import ReactFlow, {addEdge, ConnectionLineType, useEdgesState, useNodesState,} from 'react-flow-renderer';
import dagre from 'dagre';

import GraphService from "../../../service/GraphService";
import LightBtn from "../../../component/buttons";
import TaskEditorModal from "../editor/TaskEditorModal";
import ProcessGraphNode from "./ProcessGraphNode";
import TaskService from "../../../service/TaskService";

let dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 160;
const nodeHeight = 45;

/**
 * 자동 정렬된 nodes와 edges를 반환함
 * @param nodes
 * @param edges
 * @param direction
 * @returns {{nodes, edges}}
 */
const getLayoutedElements = (nodes, edges, direction = 'TB') => {

    const isHorizontal = direction === 'LR';
    dagreGraph.setGraph({rankdir: direction});

    //=dagreGraph 초기화
    dagreGraph.nodes().forEach(n => dagreGraph.removeNode(n))
    dagreGraph.edges().forEach(n => dagreGraph.removeEdge(n))


    nodes.forEach((node) => {
        dagreGraph.setNode(node.id, {width: nodeWidth, height: nodeHeight});
    });

    edges.forEach((edge) => {
        dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    const layoutNodes = nodes.map((node) => {
        const nodeWithPosition = dagreGraph.node(node.id);
        node.targetPosition = isHorizontal ? 'left' : 'top';
        node.sourcePosition = isHorizontal ? 'right' : 'bottom';

        node.position = {
            x: nodeWithPosition.x - nodeWidth / 2,
            y: nodeWithPosition.y - nodeHeight / 2,
        };

        return node;
    });


    return layoutNodes;
};

//! 10000 위 번호는 새롭게 생성된 Task를 나타냄
let id = 1;
const getId = () => 10000 + (id++);

const nodeTypes = {
    DF_NODE: ProcessGraphNode,
};


/**
 * ProcessGraphComponent
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ProcessGraph = forwardRef((props, ref) => {

    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);


    const [graphDirection, setGraphDirection] = useState("TB");

    const [refreshToggle, setRefreshToggle] = useState(false);

    const toggleRefresh = () => {
        setRefreshToggle(!refreshToggle);
    }

    const onConnect =
        (params) => {
            //! 시작지점 ( 노드의 출력 위치 )
            const src = Number(params.source);
            //! 연결지점 ( 노드의 입력 위치)
            const dst = Number(params.target);

            const dstTask = TaskService.getTask(dst)
            const changedDstTask = {
                ...dstTask,
                precdTaskId: src
            }

            TaskService.taskSaveToLocal(dst, changedDstTask);
            toggleRefresh();

        };


    useEffect(() => {
        return () => {
            initNodeEdge(TaskService.getAllTasks());
        };
    }, [refreshToggle]);


    /**
     *= 자동 정렬
     * @param direction 방향 [TB/LR]
     */

    const refreshLayout = (direction) => {
        const layoutedNodes = getLayoutedElements(
            nodes,
            edges,
            direction
        );

        setNodes((nds) => [...layoutedNodes]);
    }

    const onLayout = useCallback(refreshLayout, [nodes, edges]);

    // const [isInit, setIsInit] = useState(false);


    /**
     *= 태스크 목록을 기반으로 그래프 작성
     * @param taskList
     */
    function initNodeEdge(taskList) {
        const [_nodes, _edges] = GraphService.makeNodeEdgeByTask(taskList);

        const layoutedNodes = getLayoutedElements(
            _nodes,
            _edges,
            graphDirection
        );

        setNodes((nds) => [...layoutedNodes]);
        setEdges([..._edges]);
    }


    const reloadGraph = () => {
        TaskService.loadAndInitTask(props.prcId, (taskMap) => {
            initNodeEdge(Object.values(taskMap))
        })
    }
    const refreshGraph = () => {
        const allTask = TaskService.getAllTasks();
        initNodeEdge(allTask);
    }

    /**
     *= 컴포넌트 초기화, Task List 가져오기
     */
    useEffect(() => {
        reloadGraph()
    }, [])


    /**
     * Task Editor Modal
     */
    const [editorShow, setEditorShow] = useState(false);
    const [selectedTaskId, setSelectedTaskId] = useState(undefined);


    /**
     *= Drag & Drop 관련 기능 (노드 추가)
     */
    const reactFlowWrapper = useRef(null);
    const [reactFlowInstance, setReactFlowInstance] = useState(null);

    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);


    function addNodeByType(type, position) {
        const nodeDef = GraphService.getNodeDefByNodeType(type);
        let label = nodeDef.nodeTitle

        const tempTaskId = getId();
        const newNode = {
            id: tempTaskId,
            type: 'DF_NODE',
            position,
            data: {
                taskId: tempTaskId,
                label: label
            },
        };
        TaskService.taskSaveToLocal(tempTaskId, {
            taskId: tempTaskId,
            taskType: type,
            prcId: Number(props.prcId),
            taskNm: label,
            taskDef: {
                localProperty: {},
                inputData: {},
            },
            taskState: 'READY'
        });


        setNodes((nds) => nds.concat(newNode));
        toggleRefresh();

        setSelectedTaskId(tempTaskId);
        setEditorShow(true);

    }

    /**
     * = Drag & Drop 으로 생성 초기화
     * @type {(function(*): void)|*}
     */
    const onDrop = useCallback(
        (event) => {
            event.preventDefault();

            const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
            const type = event.dataTransfer.getData('application/reactflow');


            if (typeof type === 'undefined' || !type) {
                return;
            }

            const position = reactFlowInstance.project({
                x: event.clientX - reactFlowBounds.left,
                y: event.clientY - reactFlowBounds.top,
            });

            addNodeByType(type, position);
        },
        [reactFlowInstance]
    );

    useImperativeHandle(ref, () => ({
        refreshGraph() {
            refreshGraph();
        },
        addNodeByType(type, position){
            addNodeByType(type, position);
        }
    }));


    return (
        <>
            <TaskEditorModal prcId={props.prcId}
                             taskId={selectedTaskId}
                             show={editorShow}
                             handle={setEditorShow}
                             nodes={nodes}
                             addEdge={addEdge}
                             refreshGraph={refreshGraph}/>

            <div className={"node-graph-layout dndflow"} ref={reactFlowWrapper}
                 style={{width: props.width, height: props.height}}>
                <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    onInit={setReactFlowInstance}
                    nodeTypes={nodeTypes}
                    onDrop={onDrop}
                    onDragOver={onDragOver}
                    connectionLineType={ConnectionLineType.SmoothStep}
                    elementsSelectable={true}
                    onNodeDoubleClick={(e, node) => {
                        if (node.id === '0') return;
                        setSelectedTaskId(node.data.taskId);
                        setEditorShow(true);
                    }}
                    className="touchdevice-flow"
                    fitView
                >

                </ReactFlow>
                <div className="controls">
                    <LightBtn color={"gray"} size={"tiny"} clickHandler={() => {
                        onLayout('TB');
                        setGraphDirection("TB")
                    }}>세로 정렬</LightBtn>
                    <LightBtn color={"gray"} size={"tiny"} clickHandler={() => {
                        onLayout('LR');
                        setGraphDirection("LR")
                    }}>가로 정렬</LightBtn>
                </div>


            </div>
        </>


    );
});

export default ProcessGraph;
