import React, {Component} from "react";
import AuthenticationService from "../AuthenticationService.js";
import LoginLogo from "../../images/login_logo.png";
import "./LoginComponent.css";
import LightBtn from "../../page/component/buttons";
import Swal from "sweetalert2";

/**
 * 로그인 페이지, 로그아웃 기능 포함
 */
class LoginComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            username: "", //TODO Last LoginUser 처리 필요
            password: "",
            hasLoginFailed: false,
            showSuccessMessage: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.loginClicked = this.loginClicked.bind(this);
    }

    componentDidMount() {
        //loginPage에 들어왔다는것만으로 logout 필요
        AuthenticationService.logout();
        this.props.setUserInfo({});
        // console.log("logout");
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    loginClicked() {
        const _this = this;

        AuthenticationService.executeJwtAuthenticationService(
            this.state.username,
            this.state.password
        )
            .then((response) => {
                if (response.data.token) {
                    _this.props.setUserInfo({
                        username: this.state.username,
                        token: response.data.token,
                        expiration: response.data.expiration,
                    });

                    AuthenticationService.registerSuccessfulLoginForJwt(
                        this.state.username,
                        response.data.token,
                        response.data.expiration
                    );
                    this.setState({
                        showSuccessMessage: true,
                        hasLoginFailed: false,
                    });

                    this.props.nav(`/`);
                }
            })
            .catch(() => {
                this.setState({
                    showSuccessMessage: false,
                    hasLoginFailed: true,
                });
            });
    }

    render() {
        const _this = this;

        return (
            <div className={"hold-transition login-page"}>
                <div className="login-box">
                    {/*<div className="card card-outline card-dark">*/}
                    <div className="text-center">
                        <div className="login-logo mb-5">
                            <div className="white-mode-logo">
                                <img
                                    src={LoginLogo}
                                    alt="Sami Logo"
                                    style={{maxHeight: "150px"}}
                                />
                            </div>

                            {/* <div className="dark-mode-logo">
                                <img
                                    src={SmtLogoW}
                                    alt="Sami Logo"
                                    style={{ maxHeight: "150px" }}
                                />
                                <img
                                    src={SmtLogoTxtW}
                                    alt="Sami Logo"
                                    style={{
                                        maxHeight: "40px",
                                        marginTop: "5px",
                                    }}
                                />
                            </div> */}
                        </div>
                    </div>
                    <div>
                        <div className="input-group mb-4">
                            <input
                                type="text"
                                className="form-control"
                                name="username"
                                placeholder="User name"
                                value={this.state.username}
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className="input-group mb-4">
                            <input
                                type="password"
                                className="form-control"
                                name="password"
                                value={this.state.password}
                                onChange={this.handleChange}
                                placeholder="Password"
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") _this.loginClicked();
                                }}
                            />
                        </div>
                        <div className="login-submit mt-5">
                            {this.state.hasLoginFailed && (
                                <div className="alert alert-warning alert-login">
                                    ※ 로그인 인증 실패
                                </div>
                            )}
                            {this.state.showSuccessMessage && (
                                <div className="alert-login">로그인 성공</div>
                            )}
                            <LightBtn
                                color="green"
                                size="large"
                                clickHandler={this.loginClicked}
                            >로그인</LightBtn>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LoginComponent;
