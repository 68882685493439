import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {Card, Dropdown, DropdownButton, Stack} from "react-bootstrap";
import DataTable from 'react-data-table-component';
import DataTableStyle from "../config/DataTableStyle";
import ManageService from "../service/ManageService";
import Swal from "sweetalert2";
import memoize from "memoize-one";
import {Title2} from "../component/titles";
import LightBtn from "../component/buttons";
import ProjectCreateModal from "./component/modal/ProjectCreateModal";
import ProjectShareModal from "./component/modal/ProjectShareModal";
import ProjectCopyModal from "./component/modal/ProjectCopyModal";
import ReactTooltip from "react-tooltip";
import TextUtilService from "../../component/service/TextUtilService";


const PROJECT_COLUMNS = memoize((nav, deleteProject, newShareOpen, newCopyOpen, setPrjId, setCopyState, setNProject, newProjectOpen) => [
    {
        name: '번호',
        maxWidth: '50px',
        center: true,
        selector: row => row.prjId
    },
    {
        name: '프로젝트 명',
        selector: row => row.prj.prjNm,
    },
    {
        name: '분류',
        selector: row => row.tgtType,
    },
    {
        name: '크기',
        selector: row => TextUtilService.bytesToSize(row.size),
    },
    {
        name: '소유자',
        selector: row => row.crUsr,
    },
    {
        name: '권한',
        // selector: row => row.prmsnTp,
        cell: row =>{
            if(row.prmsnTp==="G")
                return "G (모든 권한)";
            if(row.prmsnTp==="R")
                return "R (읽기)";
            if(row.prmsnTp==="W")
                return "W (쓰기)";
            return row.prmsnTp;
        }
    },
    {
        name: '관리',
        button: true,
        minWidth: '150px',
        cell: row => <>
            <LightBtn
                size={'tiny'} color={'green'} icon={'link'}
                data-tip data-for="project"
                clickHandler={() => {
                nav(`/project/${row.prjId}`)
            }}/>
            <ReactTooltip place="left" type="dark" effect="solid" id="project">
                <span>프로젝트 관리 페이지로 이동합니다.</span>
            </ReactTooltip>
            <DropdownButton
                title={""}
            >
                <Dropdown.Item size={'sm'}
                   data-tip data-for="pjUpdate"
                   onClick={() => {
                       setNProject({
                           prjId: row.prjId,
                           prjNm: row.prj.prjNm,
                           prjDscr: row.prj.prjDscr
                       });
                       newProjectOpen();
                   }}>수정</Dropdown.Item>
                <ReactTooltip place="left" type="dark" effect="solid" id="pjUpdate">
                    <span>프로젝트 이름과 설명을 수정합니다.</span>
                </ReactTooltip>
                {row.prmsnTp !== 'G' ? "" : <Dropdown.Item size={'sm'}
                       data-tip data-for="pjShare"
                       onClick={() => {
                           setPrjId(row.prjId, row.prj.prjNm)
                           newShareOpen()
                       }}>공유</Dropdown.Item>
                }
                <ReactTooltip place="left" type="dark" effect="solid" id="pjShare">
                    <span>다른 사용자와 프로젝트를 공유하고 권한을 설정합니다.</span>
                </ReactTooltip>

                {row.prmsnTp !== 'R' ? <Dropdown.Item size={'sm'}
                      data-tip data-for="pjCopy"
                      onClick={() => {
                          setCopyState(row.prjId, row.prj.prjNm)
                          newCopyOpen()
                      }}>복사</Dropdown.Item> : ""}
                <ReactTooltip place="left" type="dark" effect="solid" id="pjCopy">
                    <span>프로젝트를 복사합니다.</span>
                </ReactTooltip>

                <Dropdown.Item size={'sm'}
                   data-tip data-for="pjDelete"
                   onClick={() => {
                       ManageService.projectDeleteCheck(row.prjId).then(r => {
                           if (r.status === 200) {
                               Swal.fire({
                                   title: '프로젝트를 삭제하시겠습니까?',
                                   html: `<p>삭제될 데이터셋 수 : ${r.data.deleteDatasetCount}</p>
                            <p>삭제될 데이터 수 : ${r.data.deleteDataCount}</p>
                            <p>삭제될 데이터 크기 : ${TextUtilService.bytesToSize(r.data.deleteDataSize)}</p>
                            <p>삭제될 파일 수 : ${r.data.deleteFilesCount}</p>
                            <p>삭제될 파일 크기 : ${TextUtilService.bytesToSize(r.data.deleteFilesSize)}</p>
                    `,
                                   type: "warning",
                                   showCancelButton: true,
                                   confirmButtonClass: "btn-danger",
                                   confirmButtonText: "예",
                                   cancelButtonText: "아니오",
                                   closeOnConfirm: false,
                                   closeOnCancel: true
                               }).then(result => {
                                   if (result.isConfirmed) {
                                       deleteProject(row.prjId);
                                   }
                               })
                           }
                       })
                       // Swal.fire("Not Implemented Yet");
                   }}>삭제</Dropdown.Item>
                <ReactTooltip place="left" type="dark" effect="solid" id="pjDelete">
                    <span>프로젝트를 삭제합니다.</span>
                </ReactTooltip>
            </DropdownButton>
        </>,

    }
]);

const ProjectBoard = (props) => {
    const nav = useNavigate();

    const [projectList, setProjectList] = useState([]);

    const reloadBoard = () => {
        ManageService.getProjectBoard().then((r) => {
            if (r !== undefined && r.status === 200) {
                var data = r.data;
                data.sort((p1, p2) => p2.crDt - p1.crDt);
                setProjectList(data);
            }
        })
    };

    useEffect(() => {
        reloadBoard();
    }, []);

    /**
     * 프로젝트 신규 생성 모달
     */
    const [isOpenNewModal, setIsOpenNewModal] = useState(false);

    const newProjectClose = () => setIsOpenNewModal(false);
    const newProjectOpen = () => setIsOpenNewModal(true);

    /**
     * 공유 관리 모달
     * */
    const [isOpenShareModal, setIsOpenShareModal] = useState(false);

    const newShareClose = () => {
        setIsOpenShareModal(false)
        setShareVo({
            prjId: 0, strgId: 0
        });
    };
    const newShareOpen = () => setIsOpenShareModal(true);

    /**
     * 프로젝트 복제 모달
     * */
    const [isOpenCopyModal, setIsOpenCopyModal] = useState(false);

    const newCopyClose = () => {
        setShareVo({
            prjId: 0, shareUser: '', permissionType: ''
        });
        setIsOpenCopyModal(false);
    }
    const newCopyOpen = () => {
        setIsOpenCopyModal(true);
    }

    /**
     * 프로젝트 생성
     */

    const [nProject, setNProject] = useState({prjNm: '', prjDscr: ''});


    const deleteProject = (prjId) => {
        ManageService.deleteProject(prjId).then((r) => {
            if (r.status === 200) {
                Swal.fire("프로젝트가 삭제되었습니다.");
                reloadBoard();
            } else {
                Swal.fire("프로젝트 삭제를 실패했습니다.")
            }

        })
    }

    //공유 기능 vo
    const [shareVo, setShareVo] = useState({prjId: 0, shareUser: '', permissionType: 'G'})

    //복제 VO
    const [copyVo, setCopyVo] = useState({prjId: 0, shareUser: '', strgId: 1, copyName: ''})

    const setPrjId = (prjId) => {
        setShareVo({...shareVo, prjId: prjId});
    }

    const setCopyState = (prjId, prjNm) => {
        setCopyVo({...copyVo, prjId: prjId, strgId: 1, copyName: prjNm});
    }

    return (
        <>
            <Card className={'data-project-list'}>
                <Card.Header>
                    <Stack direction="horizontal" gap={3}>
                        <Title2>프로젝트 목록</Title2>
                        <LightBtn
                            data-tip data-for="newPj"
                            color="green"
                            size="small"
                            clickHandler={newProjectOpen}
                        >+ 프로젝트 추가</LightBtn>
                        <ReactTooltip place="left" type="dark" effect="solid" id="newPj">
                            <span>새로운 프로젝트를 생성합니다.</span>
                        </ReactTooltip>
                    </Stack>
                </Card.Header>
                <Card.Body>
                    <DataTable
                        columns={PROJECT_COLUMNS(nav, deleteProject, newShareOpen, newCopyOpen, setPrjId, setCopyState, setNProject, newProjectOpen)}
                        data={projectList}
                        customStyles={DataTableStyle.customStyles}
                        highlightOnHover
                        // pointerOnHover
                        pagination
                    />

                </Card.Body>
            </Card>

            <ProjectCreateModal show={isOpenNewModal} onHide={newProjectClose}
                                nProject={nProject} setNProject={setNProject}
                                reloadBoard={reloadBoard}/>
            <ProjectShareModal show={isOpenShareModal} onHide={newShareClose} shareVo={shareVo} setShareVo={setShareVo}
                               reloadBoard={reloadBoard}></ProjectShareModal>
            <ProjectCopyModal show={isOpenCopyModal} onHide={newCopyClose} copyVo={copyVo} setCopyVo={setCopyVo}
                              reloadBoard={reloadBoard}></ProjectCopyModal>
        </>
    );
};

export default ProjectBoard;
