import axios from "axios";

class UserManageService {
    static loadUserList(){
        return axios.get('/api/mng/user/list')
    }
    static loadUserDatasetList(user){
        return axios.get('/api/mng/user/dataset/list', {
            params: {
                selectUser: user
            }
        })
    }
    static saveUser(userInfo) {
        return axios.post('/auth/factory/regist', userInfo)
    }
    static modifyUser(userInfo) {
        return axios.post('/auth/factory/modify', userInfo)
    }
    static deleteUser(userInfo) {
        return axios.post('/auth/factory/delete', userInfo)
    }
}

export default UserManageService