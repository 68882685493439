import React from 'react';

import {ChonkyActions, FileBrowser, FileContextMenu, FileList, FileToolbar, setChonkyDefaults} from 'chonky';
import {ChonkyIconFA} from 'chonky-icon-fontawesome';
import FileService from "../service/FileService";
import Swal from "sweetalert2";
import "./ChonkyBlack.css"
import DataService from "../service/DataService";
import {toast} from "react-toastify";

setChonkyDefaults({iconComponent: ChonkyIconFA});

export const FileDataBrowser = ({dtsId, files,loadFiles}) => {

    const handleAction = (data) => {
        var imgTypeList = ["png", "jpg", "jpeg", "bmp"];
        if (data.id === ChonkyActions.OpenFiles.id) {
            const file = data.payload.targetFile;
            const fileType = file.fileJson.fileType;
            //TODO 이미지 또는 영상만
            console.log('process.env', process.env);
            if(imgTypeList.indexOf(fileType.toLowerCase()) !== -1) {
                Swal.fire({
                    width: '80%',
                    imageWidth: '90%',
                    imageUrl: `${process.env.REACT_APP_SERVER_PROXY}/media/${file.meta.dtsId}/${file.meta.dtId}`
                        + "?fdCd=FILE&token="
                        + sessionStorage.getItem("token")
                })
            } else {
                Swal.fire({
                    width: '80%',
                    imageWidth: '90%',
                    title: '이미지 타입만 미리보기할 수 있습니다.',
                    text: `다운로드 파일: ${file.fileJson.fileName}`,
                    confirmButtonText : "다운로드"
                }).then((result) => {
                    if(result.isConfirmed) {
                        downloadFile(data);
                    }
                });
            }

            // Open the files
        } else if (data.id === ChonkyActions.DeleteFiles.id) {
            // Delete the files
            const removeIds = data.state.selectedFiles.map(sf=>sf.id);

            Swal.fire({
                title: '정말로 삭제하시겠습니까?',
                text: `삭제된 데이터는 복구할 수 없습니다. [파일 : ${removeIds.length}개]`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText:'취소',
                confirmButtonText: '삭제'
            }).then((result) => {
                if (result.isConfirmed) {
                    DataService.deleteRowData(dtsId, removeIds).then(r =>{
                        if(r.status===200){
                            toast(`삭제 성공 - ${removeIds.length}개 파일`, {type: "success", autoClose: 2000});
                            loadFiles();
                        }else {
                            toast(`삭제 실패`, {type: "error", autoClose: 2000});
                        }
                    });
                }
            })
        } else if (data.id === ChonkyActions.DownloadFiles.id) {
            // Download the files
            downloadFile(data);
        }
    };

    const downloadFile = (data) => {
        data.state.selectedFilesForAction.forEach(f => {
            FileService.downloadFileByDtId(
                f.meta.dtsId, f.meta.dtId, "FILE", f.fileJson.fileName);
        });
    };

    return (
        <div className={"chonky-black"} style={{height: 400}}>
            <FileBrowser
                files={files}
                defaultFileViewActionId={ChonkyActions.EnableListView.id}
                defaultSortActionId={ChonkyActions.SortFilesByDate}
                fileActions={[
                    ChonkyActions.DownloadFiles,
                    ChonkyActions.DeleteFiles,
                ]}
                disableDragAndDropProvider={true}
                onFileAction={handleAction}
                // disableSelection={true}
                // disableDefaultFileActions={true}
            >

                <FileToolbar/>
                <FileList/>
                <FileContextMenu/>
            </FileBrowser>
        </div>
    );
};
export default FileDataBrowser;
