import {Dropzone, FileItem} from "@dropzone-ui/react";
import React, {useState} from 'react';

export default function DropZoneUpload(props) {
    const [files, setFiles] = useState([]);

    // const [uploadLog, setUploadLog] = useState([]);
    const updateFiles = (incommingFiles) => {
        setFiles(incommingFiles);
    };
    const onDelete = (id) => {
        setFiles(files.filter((x) => x.id !== id));
    };
    return (
        <div className={"dropzone-black"}>
            <Dropzone
                onChange={updateFiles}
                value={files}
                onClean
                // accept={"audio/*,video/*,image/*,MIME_type,application/*,text/*"}
                maxFileSize={104857600}
                label={"파일을 Drag & Drop 하거나 클릭 후 선택하면 자동으로 업로드 됩니다."}
                minHeight={"195px"}
                maxHeight={"500px"}
                localization={"EN-en"}
                url={"/api/file/upload/" + props.dtsId + "?fdCd=FILE"}
                method={"POST"}
                config={{
                    headers: {
                        "content-type": "multipart/form-data"
                    },
                }}
                uploadOnDrop={true}
                onUploadFinish={(r) => {
                    console.log(r);
                    // setUploadLog(r);
                    props.loadFiles();
                    setFiles([]);
                }}
            >
                {files.map((file) => (
                    <FileItem
                        {...file}
                        key={file.id}
                        onDelete={onDelete}
                        alwaysActive
                        localization={"EN-en"}
                        preview
                        info
                        resultOnTooltip
                    />
                ))}
            </Dropzone>
        </div>
    );
}
