import React, {useEffect, useState} from 'react';
import {Card, Dropdown, DropdownButton, Stack} from "react-bootstrap";
import {Title2} from "../../page/component/titles";
import LightBtn from "../../page/component/buttons";
import ReactTooltip from "react-tooltip";
import DataTable from "react-data-table-component";
import DataTableStyle from "../../page/config/DataTableStyle";
import memoize from "memoize-one";
import {useNavigate} from "react-router-dom";
import AddUserModal from "./modal/AddUserModal";
import UserManageService from "../../page/service/UserManageService";
import Swal from "sweetalert2";

const USER_COLUMNS = memoize((nav, getListLength, setUserInfo, onShow, setUserManageInfo, deleteUser) => [
    {
        name: '유저명',
        center: true,
        selector: row => row.member.mbName
    },
    {
        name: '유저ID',
        center: true,
        selector: row => row.member.mbUsername
    },
    {
        name: '소속그룹',
        center: true,
        selector: row => row.member.mbGroup
    },
    {
        name: '권한',
        center: true,
        selector: row => row.member.mbLevel
    },
    {
        name: '보유 프로젝트 수',
        center: true,
        selector: row => getListLength(row.projectList)
    },
    {
        name: '보유 데이터셋 수',
        center: true,
        selector: row => getListLength(row.datasetList)
    },
    {
        name: '보유 프로세스 수',
        center: true,
        selector: row => getListLength(row.processList)
    },
    {
        name: '관리',
        button: true,
        minWidth: '150px',
        cell: row => <>
            <LightBtn
                size={'tiny'} color={'green'} icon={'link'}
                data-tip data-for="project"
                clickHandler={() => {
                    setUserManageInfo(row);
                    nav(`/manage/${row.member.mbUsername}`)
                }}/>
            <ReactTooltip place="left" type="dark" effect="solid" id="project">
                <span>유저 관리 상세 페이지로 이동합니다.</span>
            </ReactTooltip>
            <DropdownButton
                title={""}
            >
                <Dropdown.Item size={'sm'}
                               data-tip data-for="pjUpdate"
                               onClick={() => {
                                   onShow();
                                   setUserInfo({
                                       token: sessionStorage.getItem("token"),
                                       name: row.member.mbName,
                                       group: row.member.mbGroup,
                                       username: row.member.mbUsername,
                                       password: null,
                                       email: row.member.mbEmail,
                                       level: row.member.mbLevel,
                                       check: true
                                   });

                               }}>수정</Dropdown.Item>
                <ReactTooltip place="left" type="dark" effect="solid" id="pjUpdate">
                    <span>유저 정보를 수정합니다.</span>
                </ReactTooltip>
                <Dropdown.Item size={'sm'}
                               data-tip data-for="pjDelete"
                               onClick={() => {
                                   const deleteUserInfo = {
                                       token: sessionStorage.getItem("token"),
                                       name: row.member.mbName,
                                       group: row.member.mbGroup,
                                       username: row.member.mbUsername,
                                       password: null,
                                       email: row.member.mbEmail,
                                       level: row.member.mbLevel,
                                       check: true
                                   };
                                   Swal.fire({
                                       title : '',
                                       text : "유저를 삭제하시겠습니까?",
                                       type : "warning",
                                       showCancelButton : true,
                                       confirmButtonClass : "btn-danger",
                                       confirmButtonText : "예",
                                       cancelButtonText : "아니오",
                                       closeOnConfirm : false,
                                       closeOnCancel : true
                                   }).then(result => {
                                       if(result.isConfirmed) {
                                           deleteUser(deleteUserInfo);
                                           Swal.fire("유저가 삭제되었습니다.")
                                       }
                                   })
                               }}>삭제</Dropdown.Item>
                <ReactTooltip place="left" type="dark" effect="solid" id="pjDelete">
                    <span>유저를 삭제합니다.</span>
                </ReactTooltip>
            </DropdownButton>
        </>,

    }
]);

const UserManagePage = (props) => {

    const nav = useNavigate();


    // 유저 정보
    const [userInfo, setUserInfo] = useState({
        token: sessionStorage.getItem("token"),
        name: "",
        group: "MNTR_USER",
        username: "",
        password: null,
        email: null,
        level: 1,
        check: false
    });
    // 유저 목록
    const [userList, setUserList] = useState([]);

    // User Modal Open
    const [modalOpen, setModalOpen] = useState(false);

    // 유저 목록 불러오기
    const loadUserList = () => {
        UserManageService.loadUserList().then(r => {
            setUserList(r.data);
        });
    };

    // 리스트 길이 계산
    const getListLength = (list) => {
        if(list) {
            return list.length;
        }
        else {
            return 0;
        }
    };

    // 유저 삭제
    const deleteUser = (deleteUserInfo) => {
        UserManageService.deleteUser(deleteUserInfo).then(r => {
            if(r !== undefined && r.status === 200) {
                loadUserList();
            }
        });
    };

    // 모달 열기
    const onShow = () => {
        setModalOpen(true);
    };

    // 모달 닫기
    const onHide = () => {
        setModalOpen(false);
    };

    useEffect(() => {
        loadUserList();
    }, []);

    return (
        <div>
            <Card className={'data-project-list'}>
                <Card.Header>
                    <Stack direction="horizontal" gap={3}>
                        <Title2>유저 목록</Title2>
                        <LightBtn
                            data-tip data-for="newPj"
                            color="green"
                            size="small"
                            clickHandler={() => {
                                onShow();
                                setUserInfo({
                                    token: sessionStorage.getItem("token"),
                                    name: "",
                                    group: "MNTR_USER",
                                    username: "",
                                    password: null,
                                    email: null,
                                    level: 1,
                                    check: false
                                });
                            }}
                        >+ 유저 추가</LightBtn>
                        <ReactTooltip place="left" type="dark" effect="solid" id="newPj">
                            <span>새로운 유저를 추가합니다.</span>
                        </ReactTooltip>
                    </Stack>
                </Card.Header>
                <Card.Body>
                    <DataTable
                        columns={USER_COLUMNS(nav, getListLength, setUserInfo, onShow, props.setUserManageInfo, deleteUser)}
                        data={userList}
                        customStyles={DataTableStyle.customStyles}
                        highlightOnHover
                        // pointerOnHover
                        pagination
                        paginationPerPage={15}
                    />

                </Card.Body>
            </Card>
            <AddUserModal
                show={modalOpen}
                userInfo={userInfo}
                setUserInfo={setUserInfo}
                onHide={onHide}
                loadUserList={loadUserList}
            />
        </div>
    );
};

export default UserManagePage;
