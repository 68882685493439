import React, {useEffect, useState} from 'react';
import {Card, Modal, Tab, Tabs} from "react-bootstrap";
import LightBtn from "../../../component/buttons";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {useParams} from "react-router-dom";
import DataService from "../../../service/DataService";
import ManageService from "../../../service/ManageService";
import FileService from "../../../service/FileService";
import DropZoneExcelUpload from "../../../component/DropZoneExcelUpload";
import ErrorExcelModal from "./ErrorExcelModal";

const DatasetAllDataModal = (props) => {

    const params = useParams();

    const prjId = params.prjId?params.prjId:props.prjId;
    const dtsId = params.dtsId?params.dtsId:props.dtsId;

    const [files, setFiles] = useState([]);

    const [tabKey, setTabKey] = useState("upload");

    const [excelDataError, setExcelDataError] = useState([]);

    const [uploadType, setUploadType] = useState("add");

    if(!params.dtsId) {
        console.log(params);
    }


    const rowToFiles = (rows) => {
        return rows.datas.map(r => {

                if (!r.values['FILE']) return null;

                const dtId = r.dataMeta.dtId;
                const fileId = r.values['FILE'];

                const fileMetaKey = `${dtId}-${fileId}`;
                const fileJsonObj = rows.fileMeta[fileMetaKey];

                if(!fileJsonObj) {
                    return null;
                }

                return {
                    id: r.dataMeta.dtId,
                    name: fileJsonObj.fileName,
                    size: Number(fileJsonObj.fileSize),
                    modDate: r.dataMeta.crDt,
                    meta: r.dataMeta,
                    fileJson: fileJsonObj
                }
            }
        )
    };

    const loadFiles = () => {
        if(dtsId) {
            DataService.getFileData(dtsId, 0, 1000).then(r => {
                if (r !== undefined && r.status === 200) {
                    const rows = rowToFiles(r.data);
                    setFiles(rows);
                }
            });
        }
    };

    useEffect(() => {
        loadFiles();
    }, [dtsId]);

    return (
        <Modal
            className={props.className}
            enforceFocus={false}
            show={props.isOpenAllDataModal} onShow={() => {
                var makeExcelVO = {};
                makeExcelVO["tbFieldList"] = props.fieldList;
                makeExcelVO["dtsId"] = dtsId;
                makeExcelVO["prjId"] = prjId;
                ManageService.makeTempExcel(makeExcelVO).then((r) => {
                    // console.log("test")
                })
            }} onHide={() => {
                setUploadType("add");
                props.setIsOpenAllDataModal(false);
                setTabKey("upload");
            }}>
            <Modal.Header closeButton>
                <Modal.Title>일괄데이터 입력</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs
                    activeKey={tabKey}
                    onSelect={(e) => {
                        setTabKey(e);
                    }}
                    defaultActiveKey="upload"
                    transition={false}
                    className="mb-3 task-info-tab">
                    <Tab eventKey="upload" title="업로드">
                        <Card>
                            <Card.Header>
                                <div className={"mb-2"}>
                                    <div className="row">
                                        <div className="col">
                                            <label className={"radio-border"}>
                                                <div className="pfs-ttl">
                                                    <input defaultChecked={uploadType==="add"} type="radio" name="pfs-select"
                                                           onClick={() => {
                                                               setUploadType("add");
                                                           }}/>데이터 추가하기
                                                </div>
                                                <p className={"button-radio"}>기존 데이터를 유지하고 데이터를 등록합니다.</p>
                                            </label>
                                        </div>
                                        <div className="col">
                                            <label className={"radio-border"}>
                                                <div className="pfs-ttl">
                                                    <input defaultChecked={uploadType==="ow"} type="radio" name="pfs-select"
                                                           onClick={() => {
                                                               setUploadType("ow");
                                                           }}/>데이터 덮어쓰기
                                                </div>
                                                <p className={"button-radio"}>기존 데이터를 삭제하고 데이터를 등록합니다.</p>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </Card.Header>
                            <div className={"ms-auto"} style={{"float": "right", "position": "relative", "bottom": "0"}}>
                                <button className={"btn btn-blue btn-middle"} style={{"width":"100%"}} onClick={() => {
                                    FileService.excelDownloadFileByDtsId(dtsId,  props.dtsTitle + ".xlsx")
                                }}
                                >
                                    엑셀 양식 다운로드
                                </button>
                            </div>
                            <Card.Footer>
                                <DndProvider backend={HTML5Backend}>
                                    <DropZoneExcelUpload dtsId={dtsId} prjId={prjId}
                                                         loadFiles={loadFiles} setExcelDataError={setExcelDataError} setIsOpenAllDataModal={props.setIsOpenAllDataModal} setTabKey={setTabKey}
                                                         setExcelDataResult={props.setExcelDataResult} setExcelDataCount={props.setExcelDataCount} setExcelDataSize={props.setExcelDataSize} setExcelValidation={props.setExcelValidation}
                                                         uploadType={uploadType}
                                    />
                                </DndProvider>
                            </Card.Footer>
                        </Card>
                    </Tab>
                    <Tab eventKey="error" title="에러목록">
                        <ErrorExcelModal
                            excelDataError={excelDataError}
                        />
                    </Tab>
                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                <LightBtn color={"red"} size={"middle"} clickHandler={() => {
                    setUploadType("add");
                    props.setIsOpenAllDataModal(false)
                }}>
                    취소
                </LightBtn>
            </Modal.Footer>
        </Modal>
    );
};

export default DatasetAllDataModal;
