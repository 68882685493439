import React, {useEffect, useState} from 'react';
import {Card, Stack} from "react-bootstrap";
import {Title2} from "../../../page/component/titles";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import FileDataBrowser from "../../../page/manage/FileDataBrowser";
import DataService from "../../../page/service/DataService";

const CurrentFileData = ({dtsId, datasetField, files, setFiles}) => {

    useEffect(() => {
        if (dtsId) {
            getFileField();
            if(datasetField === null) {
                loadFiles("FILE");
            }
        }
    }, [dtsId]);

    const getFileField = () => {
        var index = 0;
        for(var i in datasetField) {
            var fdType = datasetField[i]["fdType"];
            var fdCd = datasetField[i]["fdCd"];
            if(fdType === "FILE" || fdType === "FILE_JSON") {
                loadFiles(fdCd);
            } else {
                index += 1;
            }
        }
        if(index === datasetField.length) {
            loadFiles("FILE");
        }
    };

    const loadFiles = (fdCd) => {
        if (dtsId) {
            DataService.getFileData(dtsId, 0, 1000).then(r => {
                if (r !== undefined && r.status === 200) {
                    r.data.datas = r.data.datas.sort((d1, d2) => d2.crDt - d1.crDt).slice(0, 10);
                    const rows = rowToFiles(r.data, fdCd).filter((e) => e !== null);
                    if(rows.length !== 0) {
                        setFiles(rows);
                    }
                }
            });
        }
    };

    const rowToFiles = (rows, fdCd) => {
        return rows.datas.map(r => {
                if (!r.values[fdCd]) return null;

                const dtId = r.dataMeta.dtId;
                const fileId = r.values[fdCd];

                const fileMetaKey = `${dtId}-${fileId}`;
                const fileJsonObj = rows.fileMeta[fileMetaKey];

                if(!fileJsonObj) {
                    return null;
                }

                return {
                    id: dtId,
                    name: fileJsonObj.fileName,
                    size: Number(fileJsonObj.fileSize),
                    modDate: r.dataMeta.crDt,
                    meta: r.dataMeta,
                    fileJson: fileJsonObj
                }
            }
        )
    };

    return (
        <>
            <Card className={'data-project-list'}>
                <Card.Header>
                    <Stack direction="horizontal">
                        <Title2>선택한 데이터셋에 최근 등록된 파일 데이터 (10개)</Title2>
                    </Stack>
                </Card.Header>
                <Card.Body>
                    <DndProvider backend={HTML5Backend}>
                        <FileDataBrowser dtsId={dtsId ? dtsId : 0} files={files} loadFiles={loadFiles}/>
                    </DndProvider>
                </Card.Body>
            </Card>
        </>
    );
};

export default CurrentFileData;
