import React from 'react';
import {Form} from "react-bootstrap";

const InputSelectTool = ({pName,precdTask, value, onChange}) => {
    if(!precdTask){
        return <p>선행노드가 없습니다.</p>
    }else if(!precdTask.lastTest){
        return <p>선행 노드를 먼저 테스트해주세요.</p>
    }
    return (


        <Form.Select  size="sm" value={value} onChange={(e) => {
            onChange(e.target.value)
        }}>
            <option value={''} defaultValue>미선택</option>
            {
                precdTask.lastTest.node.outputParams.map((p)=>{
                    return <option key={`${pName}-${p}`}
                                   value={p}>{p}</option>
                })

                // datasetList.map(n => {
                //     return <option key={`${prcId}-${n.dtsId}-${n.dtsType}`}
                //                    value={n.dtsId}>{`${n.dtsType} : ${n.dtsTitle}`}</option>
                // })
            }
        </Form.Select>
    );
};

export default InputSelectTool;
