import React from 'react';
import {Card, Stack} from "react-bootstrap";
import {Title2} from "../../../page/component/titles";
import DataTable from "react-data-table-component";
import DataTableStyle from "../../../page/config/DataTableStyle";
import DonutCircle from "../../../page/component/DonutCircle";
import LightBtn from "../../../page/component/buttons";
import memoize from "memoize-one";
import {Link} from "react-router-dom";
import ReactTooltip from "react-tooltip";

const DATASET_COLUMNS = memoize((projectD, setDtsId, dataUsage, convertDate, setDtIndex, setFiles) => [
    {
        name: '번호',
        maxWidth: '50px',
        center: true,
        selector: row => row.dtsId
    },
    {
        name: '데이터셋 명',
        selector: row => row.dtsTitle,
    },
    {
        name: '분류',
        selector: row => row.dtsType,
    },
    {
        name: '프로젝트',
        selector: row => {
            return <Link to={`/project/${row.prjId}`}>
                {projectD[row.prjId]}</Link>
        }
    },
    {
        name: '소유자',
        selector: row => row.crUsr,
        omit:dataUsage===null?false:true
    },
    {
        name: '최근 등록 날짜',
        selector: row => convertDate(row.dataDt)
    },
    {
        name: '관리',
        button: true,
        minWidth: '150px',
        cell: (row, index) => <>
            <LightBtn size={'tiny'} color={'green'} icon={'manage'}
                      data-tip data-for="dataSetManage"
                      clickHandler={() => {
                          setDtIndex(index);
                          setDtsId(row.dtsId);
                          setFiles([]);
                      }}/>
            <ReactTooltip place="left" type="dark" effect="solid" id="dataSetManage">
                <span></span>
            </ReactTooltip>
        </>,
    }
]);

const CurrentDatasetInfo = ({memberInfo, projectD, setDtsId, datasetList, dataUsage, dataCapacity, size, setDtIndex, setFiles}) => {

    // 날짜 타입변경 int -> date
    const convertDate = (date) => {
        var convertDate = new Date(date);
        var year = convertDate.getFullYear();
        var month = convertDate.getMonth() + 1;
        if (month < 10) month = "0" + month;
        var day = convertDate.getDate();
        if (day < 10) day = "0" + day;
        return year + "-" + month + "-" + day;
    };

    return (
        <>
            <div className={"flexGrid-2"}>
                <Card className={'data-project-info'}>
                    <Card.Header>
                        <Stack direction="horizontal">
                            <Title2>{memberInfo?memberInfo.mbName + " 님의 ":""}최근 등록된 데이터셋 정보</Title2>
                        </Stack>
                    </Card.Header>
                    <Card.Body>
                        <DataTable
                            columns={DATASET_COLUMNS(projectD, setDtsId, dataUsage, convertDate, setDtIndex, setFiles)}
                            data={datasetList?datasetList.sort((d1, d2) => d2.dataDt - d1.dataDt).slice(0, size):[]}
                            customStyles={DataTableStyle.customStyles}
                            highlightOnHover
                            // pointerOnHover
                            pagination
                        />
                    </Card.Body>
                </Card>
                {
                    dataUsage!==null?
                    <Card className={'data-project-manage'}>
                        <Card.Header>
                            <Stack direction="horizontal">
                                <DonutCircle
                                    type={"main"}
                                    dataUsage={dataUsage}
                                    dataCapacity={dataCapacity}
                                />
                                <LightBtn>목록</LightBtn>
                            </Stack>
                        </Card.Header>
                        <Card.Body>

                        </Card.Body>
                    </Card>:null
                }
            </div>
        </>
    );
};

export default CurrentDatasetInfo;
