import React, {useEffect, useState} from 'react';
import {Card, Stack} from "react-bootstrap";
import DropZoneUpload from "../component/DropZoneUpload";
import FileDataBrowser from "./FileDataBrowser";
import {useNavigate, useParams} from "react-router-dom";
import DataService from "../service/DataService";
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";
import {Title2} from "../component/titles";
import LightBtn from "../component/buttons";

const FileDataManager = (props) => {

    const nav = useNavigate();
    const params = useParams();


    const [files, setFiles] = useState([]);

    if (!params.dtsId && !props.dtsId) {
        // Swal.fire("데이터셋 정보가 없습니다.")
    }


    const rowToFiles = (rows) => {


        return rows.datas.map(r => {

                if (!r.values['FILE']) return null;

                const dtId = r.dataMeta.dtId;
                const fileId = r.values['FILE'];

                const fileMetaKey = `${dtId}-${fileId}`;
                const fileJsonObj = rows.fileMeta[fileMetaKey];

                if(!fileJsonObj) {
                    return null;
                }
                return {
                    id: dtId,
                    name: fileJsonObj.fileName,
                    size: Number(fileJsonObj.fileSize),
                    modDate: r.dataMeta.crDt,
                    meta: r.dataMeta,
                    fileJson: fileJsonObj
                }
            }
        )
    };

    const loadFiles = () => {
        if (params.dtsId || props.dtsId) {
            DataService.getFileData(params.dtsId ? params.dtsId : props.dtsId, 0, 1000).then(r => {
                if (r !== undefined && r.status === 200) {
                    const rows = rowToFiles(r.data).filter((e) => e !== null);

                    setFiles(rows);
                }
            });
        }
    };

    useEffect(() => {
        loadFiles();
    }, [params.dtsId ? params.dtsId : props.dtsId])

    return (
        <Card>
            <Card.Header>
                <Stack direction="horizontal">
                    <Title2>파일 데이터셋</Title2>
                    {params.dtsId ?
                        <LightBtn
                            color="gray"
                            size="small"
                            clickHandler={() => {
                                nav(-1)
                            }}
                        >목록</LightBtn> : ""
                    }
                </Stack>
            </Card.Header>
            <Card.Body>
                <DndProvider backend={HTML5Backend}>
                    <FileDataBrowser dtsId={params.dtsId ? params.dtsId : props.dtsId} files={files} loadFiles={loadFiles}/>
                </DndProvider>
            </Card.Body>
            <Card.Footer>
                <DndProvider backend={HTML5Backend}>
                    <DropZoneUpload dtsId={params.dtsId ? params.dtsId : props.dtsId} loadFiles={loadFiles}/>
                </DndProvider>
            </Card.Footer>
        </Card>
    );
};

export default FileDataManager;
